import sanityClient from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import { createClient } from '@sanity/client';

export const client = createClient({
    projectId: '72vajuw1',
    dataset: 'production',
    apiVersion: '2023-09-13',
    useCdn: false,
    token: 'sk2xITZm747BmraYZ1lXQWyRX4ZqHEBTSr8v1ixfEgkRH73sAEU8WUc0Q1aWaigGlocKV4XhPzhf2uiEOHNdGvkV5UYauwN88k4Mtodlu6nhkeZLHjSnnc0s0Z8dRqnilJEujTdNzyCbPmEv0IDvuSKsoe3Ya4kqix8ha5Bac4vOCk0kWQ6G'
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);